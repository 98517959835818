<template>
    <div class="store-link">
        <div class="link">
            <a :href="links.googlePlay" target="__blank">
                <img :src="img('googlePlay.png')" alt="404">
            </a>
        </div>
        <div class="link">
            <a :href="links.appleStore" target="__blank">
                <img :src="img('appStore.png')" alt="404">
            </a>
            <span class="cu">
                <a :href="links.contact">contact us</a>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            links: {
                googlePlay: "https://play.google.com/store/apps/details?id=com.akocars&referrer=utm_source=akocarsapp.com",
                contact: "#hs-chat-open",
                appleStore: "https://apps.apple.com/us/app/ako-cars/id1589532457"
            }
        }
    },

    methods: {
        img(imgName) {
            return require('@/assets/images/' + imgName)
        }
    },
}
</script>

<style lang="scss" scoped>
    .store-link {
        margin-top: 50px;
        display: flex;

        .link {
            &:nth-child(1) {
                padding-right: 25px;
            }

            .cu {
                display: block;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
                font-size: 18px;
                margin-top: 10px;

                a {
                    font-weight: bold;
                    color: #019ECF;
                }
            }
        }
    }
    
    @media(max-width: 1200px) {
        .store-link {
            margin-top: 40px;

            .link {
                img {
                    width: 200px;
                }

                .cu {
                    font-size: 16px;
                }
            }
        }
    }

    @media(max-width: 1000px) {
        .store-link {
            margin-top: 40px;
            justify-content: center;

            .link:nth-child(2) {
                padding-right: 0;
            }
        }
    }

    @media(max-width: 460px) {
        .store-link {
            margin-top: 40px;
            justify-content: center;
            flex-wrap: wrap;

    
            .link {
                &:nth-child(1) {
                    padding-right: 0;
                }

                padding: 0;
                width: 100%;
                min-height: 80px;
            }
        }
    }
</style>
